import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';

export default () => {
  const title = 'Production-like environments, on-demand.';
  const description =
    'Build and manage your modern apps deployments without starting from scratch everytime. Get containers, infrastructure, networking, monitoring, ... and much more configured easily, without any manual script.';
  const url = 'https://strio.app/thanks';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />
      <section>
      <div className="bg-lownight h-16 md:h-20" />
      <section className="relative pa-0 overflow-hidden">
        <div className="squared-background-left" />
        <div className="squared-background-right" />
        
      </section>
        <div className="flex items-center justify-center h-screen pipes-bold">
          <div className="text-center lg:px-24 px-8 pb-24 lg:w-1/2">
            <h1 className="text-3xl lg:text-4xl font-bold leading-none font-display text-lownight tracking-tight">
              Thanks, we will contact you shortly to schedule your demo.
            </h1>
            <div className="flex flex-col justify-center">
              <p className="text-xl text-lownight pt-12 px-8">
                In the meantime, why don't you learn more about Strio with our documentation?
              </p>
              <a
                  className="text-base justify-center px-8 py-3 my-8 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  href="https://docs.strio.app"
                >
                  Discover our Documentation
                </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
